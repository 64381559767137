@import url(//fonts.googleapis.com/css?family=Lato:300:400);

body {
  margin:0;
}

.header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
}


.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.waves {
  position:relative;
  display: block;
  width: 100%;
  height: 60px;
  max-height: 60px;
  margin: 0;
  z-index:5;
  bottom:0;
  left:0px;
  float:left;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 11s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 14s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 17s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 24s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 80s linear infinite;
}

/* <------------------------------------ SIDEBAR STYLES ------------------------------------> */

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 30px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #1cbef0;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

@media (min-width: 767px) {
  .bm-burger-button {
    visibility: collapse;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

@media (min-width: 767px) {
  .bm-menu-wrap {
    visibility: collapse;
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #051222;
  padding: 5em 3em 0;
  font-size: 1.15em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (min-width: 767px) {
  .bm-overlay {
    visibility: collapse;
  }
}

